import React, { useState } from "react";
import "./AdminDashboard.css"; // Include this file for custom styling
import { API_BASE_URL } from "./Config";

const AdminDashboard = () => {
  const [reference, setReference] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}admin_search.php?reference=${reference}`
      );

      const data = await response.json();

      if (data.status === "success") {
        setSearchResult(data.data);
        setError("");
      } else {
        setSearchResult(null);
        setError(data.message);
      }
    } catch (error) {
      console.error("Search error:", error);
      setError("An error occurred during search. Please try again.");
    }
  };

  const handleApprove = async (ref) => {
    try {
      const formData = new URLSearchParams();
      formData.append("reference", ref);

      const response = await fetch(`${API_BASE_URL}admin_search.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      });

      const data = await response.json();

      if (data.status === "success") {
        setMessage("User approved successfully.");
        handleSearch(); // Refresh the search result after approval
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error("Approval error:", error);
      setMessage("An error occurred during approval. Please try again.");
    }
  };

  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard</h2>
      <div className="search-container">
        <input
          type="text"
          placeholder="Enter Reference"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {error && <p className="error">{error}</p>}
      {message && <p className="message">{message}</p>}
      {searchResult && (
        <div className="result-container">
          <table>
            <thead>
              <tr>
                <th>Reference</th>
                <th>Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{searchResult.reference}</td>
                <td>
                  {searchResult.firstName} {searchResult.lastName}
                </td>
                <td>{searchResult.status}</td>
                <td>
                  {searchResult.status !== "approved" && (
                    <button onClick={() => handleApprove(searchResult.reference)}>
                      Approve
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
