import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // useParams to get the application ID
import "./SubmissionSuccess.css";
import { API_BASE_URL } from "./Config";

const SubmissionDetails = () => {
  const { applicationNo } = useParams(); // Get applicationNo from the route parameters
  const [userInfo, setUserInfo] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
           `${API_BASE_URL}get-user-details.php?applicationNo=${applicationNo}`, // Pass the applicationNo to the API
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        if (data.status === "success") {
          setUserData(data.data);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError(error.message);
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserInfo = async () => {
      try {
        const response = await fetch(
           `${API_BASE_URL}user-info.php`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user info");
        }

        const data = await response.json();
        if (data.status === "success") {
          setUserInfo(data.data);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError(
          "An error occurred while fetching user information. Please try again."
        );
        console.error("Error fetching user info:", error);
      }
    };

    fetchData();
    fetchUserInfo();
  }, [applicationNo]);

  const handlePrint = () => {
    window.print();
  };

  const handleBackToApplicationSummary = () => {
    navigate("/application-summary");
  };

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  if (loading) {
    return <p>Loading user details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!userInfo || !userData) {
    return <p>No user data available.</p>;
  }

  return (
    <div className="submission-details">
      <h1>Submission Details</h1>
      <div className="details-container">
        <h2>Personal Information</h2>
        {error && <p className="error">{error}</p>}
        <div className="personal-info">
          <div className="profile-image">
            {userData.picture ? (
              <img
                src={` ${API_BASE_URL}${userData.picture}`}
                style={{ width: '250px', height: '250px', objectFit: 'cover' }}
                alt="User"
              />
            ) : (
              <p>No Picture Uploaded</p>
            )}
          </div>
          <div className="user-details">
            <p>
              Full Name: {userInfo.firstName} {userInfo.lastName}{" "}
              {userInfo.otherName}
            </p>
            <p>
              <strong>Email: </strong>
              {userInfo.email}
            </p>
            <p>
              <strong>Phone Number: </strong>
              {userInfo.phoneNumber}
            </p>
            <p>
              <strong>Application Number: </strong>
              {userInfo.applicationNo}
            </p>
            <p>
              <strong>Programme: </strong>
              {userInfo.programme}
            </p>
            <p>
              <strong>Jamb Score: </strong>
              {userInfo.jambScore}
            </p>
            <p>
              <strong>Jamb Number: </strong>
              {userInfo.jambNumber}
            </p>
            <p>
              <strong>School: </strong>
              {userInfo.school}
            </p>
            <p>
              <strong>Session: </strong>
              {userInfo.session}
            </p>
            <p>
              <strong>Gender:</strong> {userData.gender}
            </p>
            <p>
              <strong>Date of Birth:</strong> {userData.dob}
            </p>
            <p>
              <strong>Disability:</strong> {userData.disability}
            </p>
            <p>
              <strong>Place of Birth:</strong> {userData.placeOfBirth}
            </p>
            <p>
              <strong>State of Origin:</strong> {userData.stateOfOrigin}
            </p>
            <p>
              <strong>LGA:</strong> {userData.lga}
            </p>
            <p>
              <strong>Email:</strong> {userData.email}
            </p>
            <p>
              <strong>GSM Number:</strong> {userData.gsm}
            </p>
            <p>
              <strong>Home Address:</strong> {userData.homeAddress}
            </p>
            <p>
              <strong>Next of Kin Name:</strong> {userData.nextOfKinName}
            </p>
            <p>
              <strong>Next of Kin GSM Number:</strong>{" "}
              {userData.nextOfKinGsm}
            </p>
            <p>
              <strong>Next of Kin Address:</strong>{" "}
              {userData.nextOfKinAddress}
            </p>
            <p>
              <strong>Sponsor's Name:</strong> {userData.sponsorName}
            </p>
            <p>
              <strong>Sponsor's Address:</strong> {userData.sponsorAddress}
            </p>
          </div>
        </div>
        <h2>Education Details</h2>
        {userData.educationDetails && userData.educationDetails.length > 0 ? (
          <table className="education-details">
            <thead>
              <tr>
                <th>#</th>
                <th>Institution Name</th>
                <th>From</th>
                <th>To</th>
                <th>Qualification</th>
              </tr>
            </thead>
            <tbody>
              {userData.educationDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{detail.institution_name}</td>
                  <td>{detail.from_year}</td>
                  <td>{detail.to_year}</td>
                  <td>{detail.qualification_obtained}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No education details provided.</p>
        )}

        <h2>O-Level Results</h2>
        {userData.olevelResults && userData.olevelResults.length > 0 ? (
          userData.olevelResults.map((result, index) => (
            <div key={index} className="olevel-results-container">
              <h3 className={`sitting-heading ${result.sitting_type}`}>
                {result.sitting_type === "first"
                  ? "First Sitting"
                  : "Second Sitting"}
              </h3>
              <table className="olevel-results">
                <tbody>
                  <tr>
                    <td>
                      <strong>Year:</strong> {result.year}
                    </td>
                    <td>
                      <strong>Exam Body:</strong> {result.examBody}
                    </td>
                    <td>
                      <strong>Exam Type:</strong> {result.examType}
                    </td>
                    <td>
                      <strong>Exam No:</strong> {result.examNo}
                    </td>
                  </tr>
                </tbody>
              </table>
              {result.subjects ? (
                <table className="subjects-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Subject</th>
                      <th>Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {JSON.parse(result.subjects).map(
                      (subject, subjectIndex) => (
                        <tr key={subjectIndex}>
                          <td>{subjectIndex + 1}</td>
                          <td>{subject.subject}</td>
                          <td>{subject.grade}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <p>No subjects found.</p>
              )}
            </div>
          ))
        ) : (
          <p>No O-Level results provided.</p>
        )}
        <p><strong>NOTE: </strong> Photocopies of all the statement of results, certificates,etc MUST accompany this form as a condition for consideration of admission</p>
        <h2>Declaration</h2>
        <p>I, {userInfo.firstName} {userInfo.lastName}{" "}
        {userInfo.otherName} hereby declare that the information given above is genuine and accurate .</p>
        <p className="sign">Sign & Date _______________________</p>
        <h2>For Official Use Only</h2>
        <p className="sign">Screening Officer Sign & Date _______________________</p>
      </div>
      <div className="button-container">
        <button onClick={handlePrint} className="print-button">
          Print
        </button>
        <button onClick={handleBackToApplicationSummary} className="back-button">
          Back to Application Summary
        </button>
        <button onClick={handleBackToDashboard} className="dashboard-button">
          Back to Dashboard
        </button>
      </div>
    </div>
  );
};

export default SubmissionDetails;
