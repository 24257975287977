import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Homepage.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import logo from "./img/main.jpeg";
import logo1 from "./img/fecot.jpeg";
import logo2 from "./img/cns.jpeg";
import person1 from "./img/person-1.jpg";
import person2 from "./img/person-3.jpg";
import person3 from "./img/person-6.jpg";
import { Link } from "react-router-dom";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="navbar">
      <div className="navbar-container">
        <h1 className="navbar-logo">
          <img
            src={logo1}
            alt="Left Logo"
            className="left-logo"
          />
         <Link to='/'><img src={logo} alt="Middle Logo" className="middle-logo" /></Link>
          <img
            src={logo2}
            alt="Right Logo"
            className="right-logo"
          />
        </h1>
        <nav className={`navbar-menu ${isOpen ? "active" : ""}`}>
          <ul>
            <li>
              <a href="#home" onClick={toggleMenu}>
                Home
              </a>
            </li>
           
            <li>
              <a href="#programs" onClick={toggleMenu}>
                Programs
              </a>
            </li>
            <li>
              <a href="#admissions" onClick={toggleMenu}>
                Admissions
              </a>
            </li>
            <li>
              <a href="#faculty" onClick={toggleMenu}>
                Schools
              </a>
            </li>
            <li>
              <a href="#student-life" onClick={toggleMenu}>
                Student Life
              </a>
            </li>
            <li>
              <a href="#contact" onClick={toggleMenu}>
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
        <div className="navbar-toggle" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </header>
  );
};

const HeroSection = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/register");
  };
  const handleButtonClickLogin = () => {
    navigate("/login");
  };
  const handleButtonConfirm = () => {
    
      window.location.href = 'https://admissions.nohlagos.gov.ng/confirmRRR.php';
     
   
  };

  return (
    <div className="hero-container">
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1 className="hero-title">
          National Orthopaedic Hospital Igbobi Lagos  </h1>
          <div>
         <h3 className="hero-subtitle" >College of Nursing Sciences and Federal College of Othopaedic Technology</h3>  
          
          </div>
         <div>
         <h3 className="hero-subtitle">POST UTME PORTAL</h3>
         </div>
       
        
        <p className="hero-subtitle">
          Join us in shaping the future of healthcare.
        </p>
        <button className="cta-button" onClick={handleButtonClick}>
          Apply Now
        </button>
        <button className="cta-button" onClick={handleButtonClickLogin}>
          Login
        </button>
        <button className="cta-button" onClick={handleButtonConfirm}>
          Confirm RRR Payment
        </button>
      </div>
    </div>
  );
};




const ProgramsOffered = () => {
  return (
    
    <div className="programs-container" id="programs">
       <p className="program-overview">
        {/* to be removed */}
       <h3 className="program-name">Application Closed Except for HND that is extended</h3>
       <h3 className="program-name">Disclaimer</h3>
       
<p className="hero-subtitle">Authorized Payment Method:</p>
<p >	The ONLY authorized payment method is through REMITA services. This emphasizes that any payments related to the institution should be done exclusively via this platform.</p>
 <p  className="hero-subtitle">No Third-Party Engagement:</p>
<p>	The institution has not engaged the services of any INDIVIDUAL OR ORGANIZATION to collect payments on its behalf. This is crucial in preventing fraud and ensuring that all payments go directly through the official channel.</p>
        </p>
      <h2 className="admission-title">Programs Offered</h2>

      <div className="program">
        <h3 className="program-name">Diploma in Nursing Sciences</h3>
        <p className="program-overview">
        College of Nursing Sciences at the National Orthopaedic Hospital Igbobi, Lagos, stands as a beacon of excellence in healthcare education, dedicated to nurturing the next generation of compassionate and skilled nursing professionals. Our college offers a comprehensive curriculum that combines rigorous academic coursework with hands-on clinical experience, ensuring that our students are well-prepared to meet the challenges of modern healthcare.
        </p>
        <div className="program-details">
          <div className="curriculum-highlights">
            <h4>Curriculum Highlights:</h4>
            <ul>
              <li>ND-HND Nursing Sciences</li>
            
             
            </ul>
          </div>
          <div className="duration-requirements">
            <h4>Duration & Requirements:</h4>
            <p>Duration: 4 Years (Non-terminal)</p>
            <p>Entry Requirements: SSCE/WASSCE/NABTEB or equivalent</p>
          </div>
        </div>
      </div>
      <div className="program">
        <h3 className="program-name">Diploma in Orthopaedic Cast Technology(OCT)</h3>
        <p className="program-overview">
          A foundational program designed to introduce students to the
          essentials of OCT, covering key areas such as
          healthcare operations, patient services, and medical ethics.
        </p>
        <div className="program-details">
          <div className="curriculum-highlights">
            <h4>Curriculum Highlights:</h4>
            <ul>
            
              <li>ND  OCT</li>
              <li>HND  OCT</li>
            </ul>
          </div>
          <div className="duration-requirements">
            <h4>Duration & Requirements:</h4>
            <p>ND Duration: 2 Years</p>
            <p>HND Duration: 2 Years</p>
            <p>Entry Requirements: SSCE/WASSCE/NABTEB or equivalent</p>
          </div>
        </div>
      </div>

      <div className="program">
        <h3 className="program-name">
          Bachelor's/Diploma Technology in Prosthetics and Orthotics  Technology(POT)
        </h3>
        <p className="program-overview">
          An in-depth program focused in Prosthetics/Orthotics Technologist that assess patient disabilities 
          and their physical needs. He/she design, fabricate, fit, evaluate and 
          repair prosthetic devices (artificial limbs) and orthotic devices (braces and supports)
        </p>
        <div className="program-details">
          <div className="curriculum-highlights">
            <h4>Curriculum Highlights:</h4>
            <ul>
              <li>B.Tech P&O</li>
              <li>ND POT</li>
              <li>HND POT</li>
            </ul>
          </div>
          <div className="duration-requirements">
            <h4>Duration & Requirements:</h4>
            <p>ND Duration: 2 Years</p>
            <p>HND Duration: 2 Years</p>
            <p>B.Tech Duration: 5 Years</p>
            <p>Entry Requirements: SSCE/WASSCE/NABTEB or equivalent</p>
          </div>
        </div>
      </div>

      
    </div>
  );
};

const AdmissionInformation = () => {
  return (
    <div className="admission-container" id="admissions">
      <h2 className="admission-title">Admission Information</h2>

      <div className="admission-section">
        <ol className="application-process">
          <h3 className="admission-subtitle">Application Process</h3>
          <li>Fill out the online application form with accurate details.</li>
          <li>
          Pay the application fee through  Remita secure online payment portal.            
          </li>
          <li>
          After you have paid, Login and fill your personal, next of kin and academic details.
          </li>
          <li>Print you application slip</li>
          <li>Bring it to the exam venue and the follow the instructions on it.</li>
        </ol>
        
      </div>

      <div className="tutorial-section">
        <h3 className="admission-subtitle">How to Apply</h3>
        <div className="tutorial-content">
          <div className="video-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/V-jEGnUCTaM?si=0sxt1T4zGlf0zNQ1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullscreen></iframe>
          </div>
          <div className="screenshots-container">
            <img
              src="https://images.unsplash.com/photo-1566241440091-ec10de8db2e1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2NyZWVuc2hvdHxlbnwwfHwwfHx8MA%3D%3D"
              alt="Application Form Screenshot"
              className="screenshot"
            />
            <img
              src="https://images.unsplash.com/photo-1566241440091-ec10de8db2e1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2NyZWVuc2hvdHxlbnwwfHwwfHx8MA%3D%3D"
              alt="Document Submission Screenshot"
              className="screenshot"
            />
            <img
              src="https://images.unsplash.com/photo-1566241440091-ec10de8db2e1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2NyZWVuc2hvdHxlbnwwfHwwfHx8MA%3D%3D"
              alt="Payment Portal Screenshot"
              className="screenshot"
            />
            <img
              src="https://images.unsplash.com/photo-1566241440091-ec10de8db2e1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2NyZWVuc2hvdHxlbnwwfHwwfHx8MA%3D%3D"
              alt="Payment Portal Screenshot"
              className="screenshot"
            />
            <img
              src="https://images.unsplash.com/photo-1566241440091-ec10de8db2e1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2NyZWVuc2hvdHxlbnwwfHwwfHx8MA%3D%3D"
              alt="Payment Portal Screenshot"
              className="screenshot"
            />
            <img
              src="https://images.unsplash.com/photo-1566241440091-ec10de8db2e1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2NyZWVuc2hvdHxlbnwwfHwwfHx8MA%3D%3D"
              alt="Payment Portal Screenshot"
              className="screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FacultyStaff = () => {
 

  const departments = [
    {
      name: "College Of Nursing Sciences",
      description:
        "College of Nursing Sciences at the National Orthopaedic Hospital Igbobi, Lagos, stands as a beacon of excellence in healthcare education, dedicated to nurturing the next generation of compassionate and skilled nursing professionals. Our college offers a comprehensive curriculum that combines rigorous academic coursework with hands-on clinical experience, ensuring that our students are well-prepared to meet the challenges of modern healthcare.Focuses on developing future leaders in healthcare management, offering courses in policy, leadership, and organizational behavior.",
    },
    {
      name: "Federal College of Orthopaedic Technology",
      description:
        "At the FECOT, we emphasize the importance of evidence-based practice, critical thinking, and lifelong learning. Our faculty comprises experienced educators and practitioners who are committed to mentoring students and fostering a supportive learning environment. We are proud to offer a range of programs, from undergraduate to advanced degrees, tailored to meet the diverse needs of our student body.",
    },
    
  ];

  return (
    <div className="faculty-container" id="faculty">
     

      <div className="faculty-departments">
        <h3 className="departments-title">Schools</h3>
        <div className="departments-grid">
          {departments.map((department, index) => (
            <div key={index} className="department-item">
              <h4 className="department-name">{department.name}</h4>
              <p className="department-description">{department.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const StudentLife = () => {
  const testimonials = [
    {
      name: "Sarah Johnson",
      quote:
        "The campus facilities are top-notch, providing us with everything we need to succeed in our studies. The faculty is incredibly supportive, and the extracurricular activities help us stay balanced.",
      image: person2,
    },
    {
      name: "Zainab Musa",
      quote:
        "Joining the healthcare management club was one of the best decisions I made. It has enriched my learning experience and connected me with industry professionals.",
      image: person1,
    },
    {
      name: "Emily Omoleye",
      quote:
        "The labs are well-equipped, and the learning resources available to us are exceptional. I've gained hands-on experience that has prepared me well for my career.",
      image: person3,
    },
  ];

  return (
    <div className="student-life-container" id="student-life">
      <h2 className="admission-title">Student Life</h2>

      <div className="student-life-content">
        <div className="campus-facilities">
          <h3 className="section-subtitle">Campus Facilities</h3>
          <p className="section-description">
            Our campus boasts state-of-the-art facilities including advanced
            laboratories, a comprehensive library, and modern classrooms
            designed to enhance your learning experience. The student center and
            recreation areas provide a perfect balance between academics and
            relaxation.
          </p>
        </div>

        <div className="extracurricular-activities">
          <h3 className="section-subtitle">Extracurricular Activities</h3>
          <p className="section-description">
            We offer a variety of clubs and organizations that cater to
            different interests, from healthcare management clubs to sports
            teams and cultural groups. These activities are designed to help you
            grow personally and professionally while building lasting
            friendships.
          </p>
        </div>
      </div>

      <div className="student-testimonials">
        <h3 className="section-subtitle">What Our Students Say</h3>
        <div className="testimonials-grid">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-image"
              />
              <p className="testimonial-quote">"{testimonial.quote}"</p>
              <h4 className="testimonial-name">{testimonial.name}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



const ContactUs = () => {
  return (
    <div className="contact-us-container" id="contact">
      <h2 className="admission-title">Contact Us</h2>

      <div className="contact-details">
        <div className="contact-info">
          <h3>Contact Information</h3>
          <p>
            <strong>NOHIL Phone:</strong> + (234) 8080297408
          </p>
          <p>
            <strong>CNS Phone:</strong> +2349150893963 | +2348078192852
            
          </p>
          <p>
            <strong>FECOT Phone:</strong> +2348078192869 | +2348078192857 
            
          </p>
          <p>
          <p><strong>Email:</strong> admissions@nohlagos.gov.ng</p>
          <p><strong>CNS Email:</strong> cons@nohlagos.gov.ng</p>
          <p><strong>FECOT Email:</strong> fecotregistrar@nohlagos.gov.ng</p>
          </p>
          <p>
            <strong>Address:</strong> 120/124, Ikorodu Road, Igbobi, Lagos,
            Nigeria
          </p>

          <div className="map">
            <h3>Our Location</h3>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.924494555134!2d3.3675633737298325!3d6.531220423076178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8db0455b1075%3A0x8a54309b4c8b50e2!2sNational%20Orthopaedic%20Hospital%20Igbobi%2C%20Lagos!5e0!3m2!1sen!2sng!4v1725236400977!5m2!1sen!2sng" width="100%" height="200"  style={{ border: 0 }} allowFullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            
          </div>
        </div>

        <div className="contact-form">
          <h3>Send Us a Message</h3>
          <form>
            <div className="contact-group">
              <label htmlFor="name" className="label">
                Name
              </label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="contact-group">
              <label htmlFor="email" className="label">
                Email
              </label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="contact-group">
              <label htmlFor="message" className="label">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-btn">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section quick-links">
          <h4>Quick Links</h4>
          <ul>
            
            <li>
              <a href="#admissions">Admissions</a>
            </li>
            <li>
              <a href="#programs">Programs</a>
            </li>
            <li>
              <a href="#contact">Contact Info</a>
            </li>
          </ul>
        </div>

        <div className="footer-section social-media">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
        </div>

        <div className="footer-section newsletter">
          <h4>Newsletter Signup</h4>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 National Orthopaedic Hospital Igbobi, Lagos. All rights reserved.</p>
      </div>
    </footer>
  );
};

const BTT = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector('.hero-section');
      const heroSectionHeight = heroSection ? heroSection.offsetHeight : 0;
      const scrollPosition = window.scrollY;

      // Show the button if the user scrolls past the hero section
      if (scrollPosition > heroSectionHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      id="back-to-top"
      className={`back-to-top ${isVisible ? 'show' : ''}`}
      onClick={scrollToTop}
    >
      &#8679;
    </div>
  );
};

function Homepage() {
  return (
    <div className="HomePage">
      <Navbar />
      <HeroSection />
     
      <ProgramsOffered />
      <AdmissionInformation />
      <FacultyStaff />
      <StudentLife />
     
      <ContactUs />
      <Footer />
      <BTT />
    </div>
  );
}

export default Homepage;
