//igbobi
const config = {
    development: {
      API_BASE_URL: "http://localhost/cns_remita/admission_backend/",
    },
    production: {
      API_BASE_URL: "https://admissions.nohlagos.gov.ng/",
    },
  };
//final demo
// const config = {
//   development: {
//     API_BASE_URL: "http://localhost/cns_remita/admission_backend/",
//   },
//   production: {
//     API_BASE_URL: "https://demo.nohil.ng/",
//   },
// };

  
  const environment = process.env.NODE_ENV || "development";
  
  export const API_BASE_URL = config[environment].API_BASE_URL;