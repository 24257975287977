import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginForm from "./LoginForm";
import Dashboard from "./Dashboard";
import RegistrationForm from "./RegistrationForm";
import SubmittedApplication from "./SubmittedApplication";
import ApplicationSummary from "./ApplicationSummary";
import SubmissionDetails from "./SubmissionSuccess";
import AdminDashboard from "./AdminDashboard";
import Homepage from "./Homepage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/register" element={<RegistrationForm />} /> 
        <Route path="/login" element={<LoginForm />} />
        <Route path="/SubmittedApplication/:applicationNo" element={<SubmittedApplication />} />
        <Route path="/ApplicationSummary" element={<ApplicationSummary />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/submission-details" element={<SubmissionDetails />} />
        <Route path="/admin_dashboard" element={<AdminDashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
