import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import './LoginForm.css'
import { API_BASE_URL } from "./Config";
import mainlogo from './img/main.jpeg'

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (email === "admin@gmail.com" && password === "admin") {
      console.log("Admin login successful");
      navigate("/admin_dashboard");
      return;
    }

    // Use URLSearchParams for x-www-form-urlencoded
    const formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("password", password);

    try {
      const response = await fetch(
        `${API_BASE_URL}login.php`, // Update the URL to match your backend
        {
          method: "POST",
          credentials: "include", // Include credentials if needed
          headers: {
            "Content-Type": "application/x-www-form-urlencoded", // Set content type for form-urlencoded
          },
          body: formData.toString(), // Convert formData to string
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log("Login successful");
        navigate("/dashboard");
      } else {
        setError(data.message);
        console.error("Login failed:", data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An error occurred during login. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="logo-container">
       <Link to='/'><img src={mainlogo} alt="Company" className="company-logo" /></Link>
      </div>
      <h2>Applicant Login</h2>
      <div className="form-group">
        <input
          type="text"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-input"
        />
      </div>
      <div className="form-group">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-input"
        />
      </div>
      {error && <p className="error">{error}</p>}
      <button className="btn login" onClick={handleLogin}>
        Login
      </button>
      <div className="register-link">
        <p>Don't have an account?</p>
        <Link to="/register">Register Here</Link>
      </div>
    </div>
  );
};

export default LoginForm;
