import React, { useEffect, useState } from "react";
import "./ReviewForm.css";
import { API_BASE_URL } from "./Config";

const ReviewForm = ({ formData = {}, userInfo, onSubmitAll }) => {
  const [data, setData] = useState({ ...formData, user_id: userInfo.id });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const olevelResults = JSON.parse(localStorage.getItem('olevelResults')) || {};

    setData(prevData => ({
      ...prevData,
      user_id: userInfo.id,
      olevelResults: {
        firstSitting: olevelResults.firstSitting || {
          year: "",
          examBody: "",
          examType: "",
          examNo: "",
          subjects: Array(5).fill({ subject: "", grade: "" }),
        },
        secondSitting: olevelResults.secondSitting || {
          year: "",
          examBody: "",
          examType: "",
          examNo: "",
          subjects: Array(5).fill({ subject: "", grade: "" }),
        },
      },
    }));
  }, [userInfo]);

  const handleSubmit = async () => {
    if (!validateFormData(data)) {
      alert('Please fill in all required fields.');
      return;
    }

    setIsSubmitting(true);
  
    console.log("Form data before submission:", data); // Log data before submission
  
    try {
      const formData = new FormData();
      formData.append("user_id", data.user_id); // Ensure user_id is included
      Object.keys(data).forEach(key => {
        if (key === 'picture' && data[key] instanceof File) {
          formData.append(key, data[key]);
        } else if (typeof data[key] === 'object' && data[key] !== null) {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      });
  
      console.log("Submitting data with user_id:", data.user_id); // Log user_id before sending
  
      const response = await fetch(`${API_BASE_URL}submit-all.php`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
  
      const result = await response.json();
      if (result.status === 'success') {
        console.log('Data submitted successfully');
        //alert('Data submitted successfully');
        onSubmitAll();
         // Clear localStorage and reset form states after successful submission
       localStorage.removeItem("olevelResults");
       
    
      } else {
        console.error('Error response:', result.message);
       // alert('Error submitting data: ' + result.message);
      }
    } catch (error) {
      console.error('Submission failed:', error.message);
     // alert('Submission failed: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateFormData = (data) => {
    return data && data.user_id && data.gender && data.dob && data.disability &&
      data.placeOfBirth && data.stateOfOrigin && data.lga &&
      data.email && data.gsm && data.homeAddress &&
      data.nextOfKinName && data.nextOfKinGsm && data.nextOfKinAddress &&
      data.sponsorName && data.sponsorAddress &&
      data.picture &&
      data.educationDetails && data.educationDetails.length > 0 &&
      data.olevelResults && data.olevelResults.firstSitting &&
      data.olevelResults.secondSitting;
  };

  const firstSitting= data.olevelResults?.firstSitting || {
    year: "",
    examBody: "",
    examType: "",
    examNo: "",
    subjects: Array(5).fill({ subject: "", grade: "" }),
  };
  const secondSitting = data.olevelResults?.secondSitting || {
    year: "",
    examBody: "",
    examType: "",
    examNo: "",
    subjects: Array(5).fill({ subject: "", grade: "" }),
  };

  return (
    <div className="review-form">
      <h2>Review Your Information</h2>

      <div className="section">
        <h3>Personal Information</h3>
        <div className="detail-row">
          <p><strong>Gender:</strong> {data.gender}</p>
          <p><strong>Date of Birth:</strong> {data.dob}</p>
          <p><strong>Disability:</strong> {data.disability}</p>
        </div>
        <div className="detail-row">
          <p><strong>Place of Birth:</strong> {data.placeOfBirth}</p>
          <p><strong>State of Origin:</strong> {data.stateOfOrigin}</p>
          <p><strong>LGA:</strong> {data.lga}</p>
        </div>
        <div className="detail-row">
          <p><strong>Email:</strong> {data.email}</p>
          <p><strong>GSM Number:</strong> {data.gsm}</p>
          <p><strong>Home Address:</strong> {data.homeAddress}</p>
        </div>
        <div className="detail-row">
          <p><strong>Next of Kin Name:</strong> {data.nextOfKinName}</p>
          <p><strong>Next of Kin GSM Number:</strong> {data.nextOfKinGsm}</p>
          <p><strong>Next of Kin Address:</strong> {data.nextOfKinAddress}</p>
        </div>
        <div className="detail-row">
          <p><strong>Sponsor's Name:</strong> {data.sponsorName}</p>
          <p><strong>Sponsor's Address:</strong> {data.sponsorAddress}</p>
          <p><strong></strong> </p>
        </div>
        {data.picture && (
          <div className="picture-container">
            <p><strong>Picture:</strong></p>
            <img src={URL.createObjectURL(data.picture)} alt="Uploaded" />
          </div>
        )}
      </div>
      <div className="section">
        <h3>Education Details</h3>
        {data.educationDetails && data.educationDetails.length > 0 ? (
          data.educationDetails.map((detail, index) => (
            <div key={index} className="detail-row">
              <p><strong>Institution Name:</strong> {detail.institutionName}</p>
              <p><strong>From:</strong> {detail.from}</p>
              <p><strong>To:</strong> {detail.to}</p>
              <p><strong>Qualification:</strong> {detail.qualification}</p>
            </div>
          ))
        ) : (
          <p>No education details provided.</p>
        )}
      </div>
      <div className="section">
        <h3>O-Level Results</h3>
        <div>
          <h4>First Sitting</h4>
          <div className="detail-row">
            <p><strong>Year:</strong> {firstSitting.year}</p>
            <p><strong>Exam Body:</strong> {firstSitting.examBody}</p>
            <p><strong>Exam Type:</strong> {firstSitting.examType}</p>
            <p><strong>Exam No:</strong> {firstSitting.examNo}</p>
          </div>
          {firstSitting.subjects.map((subject, index) => (
            <div key={index} className="subject-row">
              <p><strong>Subject:</strong> {subject.subject}</p>
              <p><strong>Grade:</strong> {subject.grade}</p>
            </div>
          ))}
        </div>

        <div>
          <h4>Second Sitting</h4>
          <div className="detail-row">
            <p><strong>Year:</strong> {secondSitting.year}</p>
            <p><strong>Exam Body:</strong> {secondSitting.examBody}</p>
            <p><strong>Exam Type:</strong> {secondSitting.examType}</p>
            <p><strong>Exam No:</strong> {secondSitting.examNo}</p>
          </div>
          {secondSitting.subjects.map((subject, index) => (
            <div key={index} className="subject-row">
              <p><strong>Subject:</strong> {subject.subject}</p>
              <p><strong>Grade:</strong> {subject.grade}</p>
            </div>
          ))}
        </div>
      </div>

      <button className="review-button" onClick={handleSubmit} disabled={isSubmitting}>
      {isSubmitting ? (
        <>
        Submitting<span className="dot-animate">...</span>
        </>
      ) : (
        "Submit"
      )}
      </button>
      <p>Please review your information before submission and Referesh the page after submission</p>
    </div>
  );
};

export default ReviewForm;
