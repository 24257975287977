import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userCredentials, setUserCredentials] = useState({
    username: '',
    password: '',
  });

  return (
    <UserContext.Provider value={{ userCredentials, setUserCredentials }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
