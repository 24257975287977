import React, { useState } from "react";
import './Edu_form.css'

const EduForm = ({ formData, onSubmitSuccess }) => {
  const [newDetail, setNewDetail] = useState({
    institutionName: "",
    from: "",
    to: "",
    qualification: "",
  });
  const [educationDetails, setEducationDetails] = useState(formData.educationDetails || []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewDetail(prevDetail => ({ ...prevDetail, [name]: value }));
  };

  const handleAdd = () => {
    setEducationDetails(prevDetails => [...prevDetails, newDetail]);
    setNewDetail({
      institutionName: "",
      from: "",
      to: "",
      qualification: "",
    });
    onSubmitSuccess({ educationDetails: [...educationDetails, newDetail] });
  };

  const handleRemove = (index) => {
    const updatedDetails = educationDetails.filter((_, i) => i !== index);
    setEducationDetails(updatedDetails);
    onSubmitSuccess({ educationDetails: updatedDetails });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="edu_form">
      <div className="edu_input-group">
        <div className="edu_input">
          <input
            type="text"
            name="institutionName"
            value={newDetail.institutionName}
            onChange={handleInputChange}
            placeholder=" "
          />
          <label>Name of Institution</label>
        </div>
        <div className="edu_input">
          <input
            type="text"
            name="qualification"
            value={newDetail.qualification}
            onChange={handleInputChange}
            placeholder=" "
          />
          <label>Qualification</label>
        </div>
        <div className="edu_input">
          <input
            type="date"
            name="from"
            value={newDetail.from}
            onChange={handleInputChange}
            placeholder=" "
          />
          <label>From</label>
        </div>
        <div className="edu_input">
          <input
            type="date"
            name="to"
            value={newDetail.to}
            onChange={handleInputChange}
            placeholder=" "
          />
          <label>To</label>
        </div>
        
      </div>
      <button type="button" className="edu_button" onClick={handleAdd}>Add Details</button>
      <div className="education-details">
        <table className="education-table">
          <thead>
            <tr>
              <th>Institution Name</th>
              <th>From</th>
              <th>To</th>
              <th>Qualification</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {educationDetails.map((detail, index) => (
              <tr key={index}>
                <td>{detail.institutionName}</td>
                <td>{detail.from}</td>
                <td>{detail.to}</td>
                <td>{detail.qualification}</td>
                <td>
                  <button className="edu_button" type="button" onClick={() => handleRemove(index)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default EduForm;
