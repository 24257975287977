import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { useNavigate, Link } from "react-router-dom";
import "./App.css";
import { API_BASE_URL } from "./Config";
import remita from './remitalogo.png';
import logoNOHIL from './img/main.jpeg';
import logoFECOT from './img/fecot.jpeg';
import logoCNS from './img/cns.jpeg';

function RegistrationForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    customerId: "",
    firstName: "",
    lastName: "",
    otherName: "",
    email: "",
    amount: 17000,
    narration: "NOH POST UTME",
    phoneNumber: "",
    jambNumber: "",
    jambScore: "",
    school: "",
    programme: "",
    session: `${new Date().getFullYear()}/${new Date().getFullYear() + 1}`, // Automatically populate session year
    userPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const generateReference = () => {
    const date = new Date();
    const formattedDate = date.toISOString().replace(/[-:.TZ]/g, "").slice(0, 14);
    return `NOHCNSFECOT${formattedDate}`;
  };

  

  const generateApplicationNumber = () => {
    const date = new Date();
    const year = date.getFullYear().toString().slice(-2);
    const randomNum = Math.floor(Math.random() * 100000).toString().padStart(5, "0");
    return `NOHIL/PG/${year}/${randomNum}`;
  };

  useEffect(() => {
    const reference = generateReference();
    setPaymentData((prevData) => ({
      ...prevData,
      reference: reference,
      userPassword: generatePassword(),
      applicationNo: generateApplicationNumber(),
    }));
  }, []);

  const generatePassword = () => {
    return Math.random().toString(36).slice(-8);
  };

  const validateField = (field, value) => {
    let error = "";
    if (field === "firstName" || field === "lastName") {
      if (!value) {
        error = "This field is required";
      }
    } else if (field === "email") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = "Invalid email format";
      }
    } else if (field === "phoneNumber") {
      if (!/^\d+$/.test(value)) {
        error = "Phone number must contain only digits";
      }
    } else if (field === "jambNumber") {
      const currentYear = new Date().getFullYear();
      const validYearPattern = "202[3-9]|20[3-9][0-9]"; // Matches 2023 and above
      const jambPattern = new RegExp(`^(${validYearPattern})[0-9]{8}[A-Z]{2}|[0-9]{8}[A-Z]{2}$`, 'i');
// const currentYear = new Date().getFullYear();
// const validYearPattern = "202[3-9]|20[3-9][0-9]"; // Matches 2023 and above
// const jambPattern = new RegExp(`^(${validYearPattern}[0-9]{8}[a-zA-Z]{2}|[0-9]{8}[a-zA-Z]{2})$`, 'i');

      if (!jambPattern.test(value)) {
        error = "Invalid JAMB number format";
      }
    } else if (field === "jambScore") {
      if (value < 0 || value > 400) {
        error = "JAMB score must be between 0 and 400";
      }
    } else if (field === "school") {
      if (!value) {
        error = "Please select a school";
      }
    } else if (field === "programme") {
      if (!value) {
        error = "Please select a programme";
      }
    }
    return error;
  };

  const validateForm = () => {
    const validationErrors = {};
    Object.keys(paymentData).forEach((field) => {
      const error = validateField(field, paymentData[field]);
      if (error) {
        validationErrors[field] = error;
      }
    });
    setErrors(validationErrors);
    setIsFormValid(Object.keys(validationErrors).length === 0);
  };

  useEffect(() => {
    validateForm();
  }, [paymentData]);

  const sendToBackend = async () => {

    setLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}submit_form.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          reference: paymentData.reference,
          applicationNo: paymentData.applicationNo,
          firstName: paymentData.firstName,
          lastName: paymentData.lastName,
          otherName: paymentData.otherName,
          email: paymentData.email,
          phoneNumber: paymentData.phoneNumber,
          programme: paymentData.programme,
          jambNumber: paymentData.jambNumber,
          jambScore: paymentData.jambScore,
          school: paymentData.school,
          session: paymentData.session,
          amount: paymentData.amount,
          password: paymentData.userPassword,
        }).toString(),
      });

      const jsonResponse = await response.json();

      if (jsonResponse.status !== "success") {
        throw new Error(jsonResponse.message);
      }

      setErrorMessage(""); // Clear any previous errors

      setTransactionDetails({
        status: "Successful",
        ...paymentData,
        rrr: jsonResponse.rrr, // Add the generated RRR to the transaction details
      });
      
      setFormVisible(false); // Hide the form on successful submission
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error.message);
      
      
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    console.log("Submitting data:", paymentData);
    await sendToBackend();
    //sendEmails();
    // setTransactionDetails({
    //   status: "Successful",
    //   ...paymentData,
    // });
    //setFormVisible(false);
  };

  const SERVICE_ID = "service_y5g1j6n";
  const TEMPLATE_RECEIPT_ID = "template_lckqpvw";
  const TEMPLATE_PASSWORD_ID = "template_r0c0wbj";
  const USER_ID = "EHFBQa5HL4Gi11N67";
 
  const sendEmails = () => {
    const emailParams = {
      to_name: `${paymentData.firstName} ${paymentData.lastName}`,
      to_email: paymentData.email,
      reference: paymentData.reference,
      applicationNo: paymentData.applicationNo,
      firstName: paymentData.firstName,
      lastName: paymentData.lastName,
      otherName: paymentData.otherName,
      email: paymentData.email,
      phoneNumber: paymentData.phoneNumber,
      programme: paymentData.programme,
      school: paymentData.school,
      session: paymentData.session,
      amount: paymentData.amount,
      password: paymentData.userPassword,
    };

    // Send receipt email
    emailjs
      .send(SERVICE_ID, TEMPLATE_RECEIPT_ID, emailParams, USER_ID)
      .then((response) => {
        console.log("Receipt Email sent successfully", response.status, response.text);
      })
      .catch((err) => {
        console.error("Failed to send receipt email", err);
        //alert(`Failed to send receipt email: ${err.text}`);
      });

    // Send password email
    emailjs
      .send(SERVICE_ID, TEMPLATE_PASSWORD_ID, emailParams, USER_ID)
      .then((response) => {
        console.log("Password Email sent successfully", response.status, response.text);
      })
      .catch((err) => {
        console.error("Failed to send password email", err);
      });
  };

  const handleChange = (e, field) => {
    const value = e.target.value;
    setPaymentData((prevData) => ({ ...prevData, [field]: value }));

    if (field === "school") {
      if (value === "FECOT") {
        setPaymentData((prevData) => ({
          ...prevData,
          programme: "",
          programmes: [
          
            "HND POT",
           
            "HND OCT",
            "B.Tech P&O"
          ]
          //to be removed
          // programmes: [
          //   "ND POT",
          //   "HND POT",
          //   "ND OCT",
          //   "HND OCT",
          //   "B.Tech P&O"
          // ]
        }));
      } else if (value === "CNS") {
        setPaymentData((prevData) => ({
          ...prevData,
          programme: "ND/HND Nursing Sciences",
           programmes: ["Closed"]
          // programmes: ["ND/HND Nursing Sciences"]
        }));
      }
    }
  };

  const handleBlur = (e, field) => {
    const error = validateField(field, paymentData[field]);
    setTouched((prevTouched) => ({ ...prevTouched, [field]: true }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  };

  const handlePrint = () => {
    window.print();
  };

  const handleCancel = () => {
    setPaymentData({
      customerId: "",
      firstName: "",
      lastName: "",
      otherName: "",
      email: "",
      amount: 17000,
      narration: "NOH UTME",
      phoneNumber: "",
      jambNumber: "",
      jambScore: "",
      school: "",
      programme: "",
      session: `${new Date().getFullYear()}/${new Date().getFullYear() + 1}`,
      userPassword: "",
    });
    setTransactionDetails(null);
    setFormVisible(true);
    setErrorMessage("");
  };

  const handleProceed = () => {
    navigate("/login");
  };
  
  const formatLabel = (label) => {
    return label.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
  };

  const requiredFields = [
    "reference",
    "applicationNo",
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "programme",
    "jambNumber",
    "jambScore",
    "school",
    "session",
    "amount",
    "userPassword",
    "rrr" // Added RRR to the list of required fields
  ];

  return (
    <div className="App">
      <>
        {formVisible ? (
          <div className="form-container">
            <div className="logos">
              <img src={logoFECOT} alt="FECOT" className="school-logo" />
              <img src={logoNOHIL} alt="NOHIL" className="hospital-logo" />
              <img src={logoCNS} alt="CNS" className="school-logo" />
            </div>
            <h4>POST UTME FORM</h4>
            <div className="form-group">
              <input
                type="text"
                placeholder=" "
                onChange={(e) => handleChange(e, "firstName")}
                onBlur={(e) => handleBlur(e, "firstName")}
                value={paymentData.firstName}
                required
              />
              <label>First name</label>
              {touched.firstName && errors.firstName && <div className="error">{errors.firstName}</div>}
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder=" "
                onChange={(e) => handleChange(e, "lastName")}
                onBlur={(e) => handleBlur(e, "lastName")}
                value={paymentData.lastName}
                required
              />
              <label>Last name</label>
              {touched.lastName && errors.lastName && <div className="error">{errors.lastName}</div>}
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder=" "
                onChange={(e) => handleChange(e, "otherName")}
                onBlur={(e) => handleBlur(e, "otherName")}
                value={paymentData.otherName}
                required
              />
              <label>Other name</label>
              
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder=" "
                onChange={(e) => handleChange(e, "email")}
                onBlur={(e) => handleBlur(e, "email")}
                value={paymentData.email}
                required
              />
              <label>Email</label>
              {touched.email && errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div className="form-group">
              <input
                type="tel"
                placeholder=" "
                onChange={(e) => handleChange(e, "phoneNumber")}
                onBlur={(e) => handleBlur(e, "phoneNumber")}
                value={paymentData.phoneNumber}
                required
              />
              <label>Phone Number</label>
              {touched.phoneNumber && errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder=" "
                onChange={(e) => handleChange(e, "jambNumber")}
                onBlur={(e) => handleBlur(e, "jambNumber")}
                value={paymentData.jambNumber}
                required
              />
              <label>JAMB Number</label>
              {touched.jambNumber && errors.jambNumber && <div className="error">{errors.jambNumber}</div>}
            </div>
            <div className="form-group">
            <input
              type="number"
              placeholder=" "
              onChange={(e) => handleChange(e, "jambScore")}
              onBlur={(e) => handleBlur(e, "jambScore")}
              value={paymentData.jambScore}
              required
              maxLength={3}
            />
            <label>JAMB Score</label>
            {touched.jambScore && errors.jambScore && <div className="error">{errors.jambScore}</div>}
            </div>
            <div className="form-group">
              <select 
                onChange={(e) => handleChange(e, "school")} 
                onBlur={(e) => handleBlur(e, "school")}
                value={paymentData.school} 
                required>
                <option value="">Select School</option>
                <option value="FECOT">FECOT</option>
                <option value="CNS">CNS</option>
              </select>
              {touched.school && errors.school && <div className="error">{errors.school}</div>}
            </div>
            <div className="form-group">
              <select 
                onChange={(e) => handleChange(e, "programme")} 
                onBlur={(e) => handleBlur(e, "programme")}
                value={paymentData.programme} 
                required>
                <option value="">Select Program</option>
                {paymentData.programmes &&
                  paymentData.programmes.map((program) => (
                    <option key={program} value={program}>
                      {program}
                    </option>
                  ))}
              </select>
              {touched.programme && errors.programme && <div className="error">{errors.programme}</div>}
            </div>
            <div className="form-group">
              <select 
                onChange={(e) => handleChange(e, "session")}
                onBlur={(e) => handleBlur(e, "session")} 
                value={paymentData.session} 
                required>
                <option value={paymentData.session}>
                  {paymentData.session}
                </option>
              </select>
              {touched.session && errors.session && <div className="error">{errors.session}</div>}
            </div>

            <div className="btn-container">
              <button className="btn cancel" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className={`btn pay ${!isFormValid ? "disabled" : ""}`}
                onClick={handleSubmit}
                disabled={!isFormValid || loading}
              >
                
      {loading && <div className="loader"></div>}
      {loading ? "Submitting..." : "Submit"}
      
              </button>
            </div>
            <p className="secured-notice">~ Secured by <img src={remita} alt="Remita" className="remita-logo"/> ~</p>
            {errorMessage && <div className="error-message">Network Error:{errorMessage}</div>}
            <p>Already have an account? <Link to="/login">Login Here</Link></p>
           
          </div>
          
        ) : (
          <div className="transaction-details transaction-container">
            <h4>
              UTME Application {transactionDetails?.status || "Result"}
            </h4>
            <table>
              <tbody>
                {requiredFields.map((field, index) => {
                  const value =
                    transactionDetails && transactionDetails[field]
                      ? transactionDetails[field]
                      : paymentData[field];

                  return (
                    <tr
                      key={field}
                      className={index % 2 === 0 ? "light-row" : "dark-row"}
                    >
                      <td className="label">{formatLabel(field)}</td>
                      <td className="value">{value}</td>
                    </tr>
                  );
                })}
                {/* Display the generated RRR */}
                {transactionDetails?.rrr && (
                  <tr className="light-row">
                    <td className="label">RRR</td>
                    <td className="value">{transactionDetails.rrr}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="important-notice">
              <span>Important! </span>Please take a screenshot this page for
              your records. Your registration details has been sent to your inbox if not found please check your spam folder!
              
            </div>
            <div className="btn-container">
              {/* <button className="btn proceed" onClick={handleProceed}>
                Login
              </button> */}
                            {/* <a className="btn proceed"
                href={`http://localhost/cns_new/utme/paynow.php?AppNo=${transactionDetails.applicationNo}`}
                style={{ textDecoration: 'none', textAlign: 'center' }}
              >
                Get your RRR | Make Payment
              </a> */}
                          <a
  className="btn proceed"
  href={`https://admissions.nohlagos.gov.ng/paynow.php?AppNo=${encodeURIComponent(
    transactionDetails.applicationNo
  )}&email=${encodeURIComponent(transactionDetails.email)}&jambReg=${encodeURIComponent(
    transactionDetails.jambNumber
  )}`}
  style={{ textDecoration: 'none', textAlign: 'center' }}
>
  PAY NOW
</a>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default RegistrationForm;
