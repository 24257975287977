import React, { useState, useEffect } from "react";
import styles from "./OLevelResultsForm.module.css";

const OLevelResultsForm = ({ formData, onSubmitSuccess }) => {
  const [firstSitting, setFirstSitting] = useState({
    year: "",
    examBody: "",
    examType: "",
    examNo: "",
    subjects: Array(5).fill({ subject: "", grade: "" }),
  });

  const [secondSitting, setSecondSitting] = useState({
    year: "",
    examBody: "",
    examType: "",
    examNo: "",
    subjects: Array(5).fill({ subject: "", grade: "" }),
  });

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("olevelResults")) || {};
    if (savedData.firstSitting) setFirstSitting(savedData.firstSitting);
    if (savedData.secondSitting) setSecondSitting(savedData.secondSitting);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "olevelResults",
      JSON.stringify({ firstSitting, secondSitting })
    );
  }, [firstSitting, secondSitting]);

  const handleInputChange = (event, sitting) => {
    const { name, value } = event.target;
    if (sitting === "first") {
      setFirstSitting((prev) => ({ ...prev, [name]: value }));
    } else {
      setSecondSitting((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubjectChange = (event, sitting, index) => {
    const { name, value } = event.target;
    if (sitting === "first") {
      setFirstSitting((prev) => {
        const updatedSubjects = [...prev.subjects];
        updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
        return { ...prev, subjects: updatedSubjects };
      });
    } else {
      setSecondSitting((prev) => {
        const updatedSubjects = [...prev.subjects];
        updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
        return { ...prev, subjects: updatedSubjects };
      });
    }
  };

  const handleAddSubject = (sitting) => {
    if (sitting === "first") {
      setFirstSitting((prev) => ({
        ...prev,
        subjects: [...prev.subjects, { subject: "", grade: "" }],
      }));
    } else {
      setSecondSitting((prev) => ({
        ...prev,
        subjects: [...prev.subjects, { subject: "", grade: "" }],
      }));
    }
  };

  const handleRemoveSubject = (sitting, index) => {
    if (sitting === "first") {
      setFirstSitting((prev) => ({
        ...prev,
        subjects: prev.subjects.filter((_, i) => i !== index),
      }));
    } else {
      setSecondSitting((prev) => ({
        ...prev,
        subjects: prev.subjects.filter((_, i) => i !== index),
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitSuccess({
      firstSitting,
      secondSitting,
    });
  };
  const currentYear = new Date().getFullYear();
const years = Array.from({ length: 50 }, (_, i) => currentYear - i);
 // const years = ["2020", "2021", "2022", "2023","2024"];
  const examBodies = ["WAEC", "NECO", "NABTEB"];
  const examTypes = ["SSCE", "GCE","NABTEB"];
  const subjectsList = [
    "Maths",
    "English",
    "Biology",
    "Chemistry",
    "Physics",
    "Geography",
    "Economics",
    "Government",
    "Literature",
  ];
  const grades = ["A1", "B2", "B3", "C4", "C5", "C6", "D7", "E8", "F9","AR"];

  return (
    <div className={styles["form-container"]}>
      <form onSubmit={handleSubmit} className={styles.o_level_form}>
        <div className={`${styles["form-group"]} ${styles["sitting-group"]}`}>
          <h3>First Sitting</h3>
          <div className={styles["input-fields-container"]}>
            <div className={styles["input-fields"]}>
              <input
                type="text"
                name="examNo"
                value={firstSitting.examNo}
                onChange={(e) => handleInputChange(e, "first")}
                placeholder=" "
                className={styles["exam-input"]}
                required
              />
              <label htmlFor="examNo">Exam No</label>
            </div>
            <div className={styles["input-fields"]}>
              <select
                name="year"
                value={firstSitting.year}
                onChange={(e) => handleInputChange(e, "first")}
                className={styles["exam-input"]}
                required
              >
                <option value="">Select Year</option>
                {years.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <label htmlFor="year">Year</label>
            </div>
            <div className={styles["input-fields"]}>
              <select
                name="examBody"
                value={firstSitting.examBody}
                onChange={(e) => handleInputChange(e, "first")}
                className={styles["exam-input"]}
                required
              >
                <option value="">Select Exam Body</option>
                {examBodies.map((body, index) => (
                  <option key={index} value={body}>
                    {body}
                  </option>
                ))}
              </select>
              <label htmlFor="examBody">Exam Body</label>
            </div>
            <div className={styles["input-fields"]}>
              <select
                name="examType"
                value={firstSitting.examType}
                onChange={(e) => handleInputChange(e, "first")}
                className={styles["exam-input"]}
                required
              >
                <option value="">Select Exam Type</option>
                {examTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <label htmlFor="examType">Exam Type</label>
            </div>
          </div>
          {firstSitting.subjects.map((subject, index) => (
            <div key={index} className={styles["input-group"]}>
              <div className={styles["input-fields"]}>
                <select
                  name="subject"
                  value={subject.subject}
                  onChange={(e) => handleSubjectChange(e, "first", index)}
                  className={styles["exam-input"]}
                  required
                >
                  <option value="">Select Subject</option>
                  {subjectsList.map((subject, index) => (
                    <option key={index} value={subject}>
                      {subject}
                    </option>
                  ))}
                </select>
                <label htmlFor="subject">Subject</label>
              </div>
              <div className={styles["input-fields"]}>
                <select
                  name="grade"
                  value={subject.grade}
                  onChange={(e) => handleSubjectChange(e, "first", index)}
                  className={styles["exam-input"]}
                  required
                >
                  <option value="">Select Grade</option>
                  {grades.map((grade, index) => (
                    <option key={index} value={grade}>
                      {grade}
                    </option>
                  ))}
                </select>
                <label htmlFor="grade">Grade</label>
              </div>
              <button
                type="button"
                onClick={() => handleRemoveSubject("first", index)}
                className={styles["remove-subject-btn"]}
              >
                x
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddSubject("first")}
            className={styles["add-subject-btn"]}
          >
            Add Subject
          </button>
        </div>
        <div className={`${styles["form-group"]} ${styles["sitting-group"]}`}>
          <h3>Second Sitting</h3>
          <div className={styles["input-fields-container"]}>
            <div className={styles["input-fields"]}>
              <input
                type="text"
                name="examNo"
                value={secondSitting.examNo}
                onChange={(e) => handleInputChange(e, "second")}
                placeholder=" "
                className={styles["exam-input"]}
                required
              />
              <label htmlFor="examNo">Exam No</label>
            </div>
            <div className={styles["input-fields"]}>
              <select
                name="year"
                value={secondSitting.year}
                onChange={(e) => handleInputChange(e, "second")}
                className={styles["exam-input"]}
                required
              >
                <option value="">Select Year</option>
                {years.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <label htmlFor="year">Year</label>
            </div>
            <div className={styles["input-fields"]}>
              <select
                name="examBody"
                value={secondSitting.examBody}
                onChange={(e) => handleInputChange(e, "second")}
                className={styles["exam-input"]}
              >
                <option value="">Select Exam Body</option>
                {examBodies.map((body, index) => (
                  <option key={index} value={body}>
                    {body}
                  </option>
                ))}
              </select>
              <label htmlFor="examBody">Exam Body</label>
            </div>
            <div className={styles["input-fields"]}>
              <select
                name="examType"
                value={secondSitting.examType}
                onChange={(e) => handleInputChange(e, "second")}
                className={styles["exam-input"]}
              >
                <option value="">Select Exam Type</option>
                {examTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <label htmlFor="examType">Exam Type</label>
            </div>
          </div>
          {secondSitting.subjects.map((subject, index) => (
            <div key={index} className={styles["input-group"]}>
              <div className={styles["input-fields"]}>
                <select
                  name="subject"
                  value={subject.subject}
                  onChange={(e) => handleSubjectChange(e, "second", index)}
                  className={styles["exam-input"]}
                >
                  <option value="">Select Subject</option>
                  {subjectsList.map((subject, index) => (
                    <option key={index} value={subject}>
                      {subject}
                    </option>
                  ))}
                </select>
                <label htmlFor="subject">Subject</label>
              </div>
              <div className={styles["input-fields"]}>
                <select
                  name="grade"
                  value={subject.grade}
                  onChange={(e) => handleSubjectChange(e, "second", index)}
                  className={styles["exam-input"]}
                >
                  <option value="">Select Grade</option>
                  {grades.map((grade, index) => (
                    <option key={index} value={grade}>
                      {grade}
                    </option>
                  ))}
                </select>
                <label htmlFor="grade">Grade</label>
              </div>
              <button
                type="button"
                onClick={() => handleRemoveSubject("second", index)}
                className={styles["remove-subject-btn"]}
              >
                x
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddSubject("second")}
            className={styles["add-subject-btn"]}
          >
            Add Subject
          </button>
        </div>
      </form>
    </div>
  );
};

export default OLevelResultsForm;
