import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./SubmittedApplication.css";
import { API_BASE_URL } from "./Config";
import comnibenedLogo from "./img/combined_logo.png";

const SubmittedApplication = () => {
  const { applicationNo } = useParams();
  const decodedApplicationNo = decodeURIComponent(applicationNo);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}get-user-applications.php?applicationNo=${decodedApplicationNo}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        if (data.status === "success") {
          setUserData(data.data);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError(error.message);
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [decodedApplicationNo]);

  const handlePrint = () => {
    window.print();
  };

  const handleBackToApplicationSummary = () => {
    navigate("/ApplicationSummary");
  };

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  if (loading) {
    return <p>Loading user details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!userData) {
    return <p>No user data available.</p>;
  }

  return (
    <div className="submitted-application">
      <div className="header-container">
        <div className="header-right-header">
          <img src="/combined_logo.png" alt="Company" className="company-logo" />
          <h1>NATIONAL ORTHOPAEDIC HOSPITAL IGBOBI, LAGOS</h1>
          <h2>
            FEDERAL COLLEGE OF ORTHOPAEDIC TECHNOLOGY AND COLLEGE OF NURSING
            SCIENCES
          </h2>
          <p>120/124, Ikorodu Road, Igbobi, Lagos.</p>
          <p>Tel: 08080297408</p>
          <p>P.M.B. 2009, Yaba, 101212, Lagos</p>
          <p>Email: admissions@nohlagos.gov.ng</p>
          <p>Website: www.nohlagos.gov.ng</p>
          <h2>Post UTME Screening Form</h2>
        </div>
      </div>
      <h1>Application Details</h1>
      <div className="details-container">
        <h2>Personal Information</h2>
        <div className="personal-info">
          <div className="header-right">
            {userData.personalDetails?.picture ? (
              <img
                src={`${API_BASE_URL}${userData.personalDetails.picture}`}
                className="user-image"
                alt="User"
              />
            ) : (
              <p>No Picture Uploaded</p>
            )}
          </div>
          <div className="user-details">
            <p>
              Full Name: {`${userData.firstName} ${userData.lastName} ${userData.otherName}`}
            </p>
            <p>Email: {userData.email}</p>
            <p>Phone Number: {userData.phoneNumber}</p>
            <p>Application Number: {userData.applicationNo}</p>
            <p>Programme: {userData.programme}</p>
            <p>Jamb Score: {userData.jambScore}</p>
            <p>Jamb Number: {userData.jambNumber}</p>
            <p>School: {userData.school}</p>
            <p>Session: {userData.session}</p>
            <p>Gender: {userData.personalDetails?.gender || "N/A"}</p>
            <p>Date of Birth: {userData.personalDetails?.dob || "N/A"}</p>
            <p>Home Address: {userData.personalDetails?.homeAddress || "N/A"}</p>
            <p>
              Next of Kin Name: {userData.personalDetails?.nextOfKinName || "N/A"}
            </p>
            <p>
              Next of Kin GSM Number: {userData.personalDetails?.nextOfKinGsm || "N/A"}
            </p>
            <p>
              Next of Kin Address: {userData.personalDetails?.nextOfKinAddress || "N/A"}
            </p>
          </div>
        </div>

        <h2>Education Details</h2>
        {userData.educationDetails && userData.educationDetails.length > 0 ? (
          <table className="education-details">
            <thead>
              <tr>
                <th>#</th>
                <th>Institution Name</th>
                <th>From</th>
                <th>To</th>
                <th>Qualification</th>
              </tr>
            </thead>
            <tbody>
              {/* Ensuring unique education details are displayed without duplication */}
              {userData.educationDetails
                .filter(
                  (detail, index, self) =>
                    index === self.findIndex((t) => t.institution_name === detail.institution_name)
                )
                .map((detail, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{detail.institution_name}</td>
                    <td>{detail.from_year}</td>
                    <td>{detail.to_year}</td>
                    <td>{detail.qualification_obtained}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p>No education details provided.</p>
        )}

        <h2>O-Level Results</h2>
        <div className="olevel-container">
          {userData.olevelResults && userData.olevelResults.length > 0 ? (
            userData.olevelResults
              .filter(
                (result, index, self) =>
                  index === self.findIndex((t) => t.sitting_type === result.sitting_type)
              )
              .map((result, index) => (
                <div key={index} className="olevel-results-container">
                  <h3 className={`sitting-heading ${result.sitting_type}`}>
                    {result.sitting_type === "first"
                      ? "First Sitting"
                      : "Second Sitting"}
                  </h3>
                  <table className="olevel-results">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Year:</strong> {result.year}
                        </td>
                        <td>
                          <strong>Exam Body:</strong> {result.examBody}
                        </td>
                        <td>
                          <strong>Exam Type:</strong> {result.examType}
                        </td>
                        <td>
                          <strong>Exam No:</strong> {result.examNo}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {result.subjects ? (
                    <table className="subjects-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Subject</th>
                          <th>Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {JSON.parse(result.subjects).map(
                          (subject, subjectIndex) => (
                            <tr key={subjectIndex}>
                              <td>{subjectIndex + 1}</td>
                              <td>{subject.subject}</td>
                              <td>{subject.grade}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <p>No subjects found.</p>
                  )}
                </div>
              ))
          ) : (
            <p>No O-Level results provided.</p>
          )}
        </div>

        <p>
          <strong>NOTE:</strong> Photocopies of all the statement of results,
          certificates, etc., MUST accompany this form as a condition for
          consideration of admission.
        </p>
        <div className="declaration-shift">
          <h2>Declaration</h2>
          <p>
            I, {userData.firstName} {userData.lastName} {userData.otherName},
            hereby declare that the information given above is genuine and
            accurate.
          </p>
          <p className="sign">Sign & Date _______________________</p>
          <h2>For Official Use Only</h2>
          <p className="sign">Screening Officer Sign & Date _______________________</p>
          <p className="contact-info">
            For more information contact: 09150893963 or 08078192852
          </p>
        </div>
        <div className="button-container">
          <button onClick={handlePrint} className="print-button">
            Print
          </button>
          <button
            onClick={handleBackToApplicationSummary}
            className="back-button"
          >
            Back to Application Summary
          </button>
          <button onClick={handleBackToDashboard} className="back-dashboard-button">
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmittedApplication;
