import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import "./Dashboard.css";
import {
  FaHome,
  FaFolderOpen,
  FaSignOutAlt,
  FaBars,
} from "react-icons/fa";
import BioForm from "./Bio_Form";
import EduForm from "./Edu_form";
import OLevelResultsForm from "./OLevelResultsForm";
import ReviewForm from "./ReviewForm";
import SubmissionDetails from "./SubmissionSuccess";
// import logo from "./img/logo.webp";
import imgHolder  from "./img/img-holder.jpg"
import axios from "axios";
import { API_BASE_URL } from "./Config";
const Dashboard = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [userExist, setUserExist] = useState(null);
  const [error, setError] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    gender: "",
    dob: "",
    disability: "",
    placeOfBirth: "",
    stateOfOrigin: "",
    lga: "",
    // email: "",
    // gsm: "",
    homeAddress: "",
    nextOfKinName: "",
    nextOfKinGsm: "",
    nextOfKinAddress: "",
    sponsorName: "",
    sponsorAddress: "",
    picture: null,
    educationDetails: [],
    olevelResults: {
      firstSitting: {
        year: "",
        examBody: "",
        examType: "",
        examNo: "",
        subjects: Array(9).fill({ subject: "", grade: "" }),
      },
      secondSitting: {
        year: "",
        examBody: "",
        examType: "",
        examNo: "",
        subjects: Array(9).fill({ subject: "", grade: "" }),
      },
    },
  });
  const [formStatus, setFormStatus] = useState([false, false, false, false]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
const [applicationNo, setApplicationNo] = useState(null); // Store the application number for the print link

 ////fetching already existing record
 const fetchExistUserInfo = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}get-user-details.php`,
      {
        withCredentials: true,
      }
    );

    if (response.data.status === "success") {
      
      // If the user exists, set userExist to true
      setUserExist(true);
    } else if (response.data.status === "User not found") {
      // If the user does not exist, set userExist to false
      setUserExist(false);
    }
  } catch (error) {
    console.error("Error fetching user info:", error);
    setError(
      "An error occurred while fetching user information. Please try again."
    );
    // Optionally set userExist to false in case of an error
    setUserExist(false);
  }
};

useEffect(() => {
  fetchExistUserInfo();
}, []);

  const navigate = useNavigate(); // Initialize useNavigate for routing
 
  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}user-info.php`,
        {
          withCredentials: true,
        }
      );

      if (response.data.status === "success") {
        setUserInfo(response.data.data);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      setError(
        "An error occurred while fetching user information. Please try again."
      );
    }
  };
 
  useEffect(() => {
    fetchUserInfo();
  }, []);
  

  const handleContinueClick = () => {
    setCurrentStep(1);
  };
 
  const handleFormSubmit = (data, step) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    setFormStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[step] = true;
      return newStatus;
    });
    if (step < 4) {
      setCurrentStep(step + 1);
    } else {
      setCurrentStep(0);
    }
  };

  const handlePreviousClick = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  function refreshPage() {
    window.location.reload();
}
  // const handleSubmitAll = async () => {
  //   try {
  //     const response = await fetch(
  //       `${API_BASE_URL}submit-all.php`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formData),
  //         credentials: "include",
  //       }
  //     );
  //     const result = await response.json();
  //     if (result.status === "success") {
  //     console.log("All details submitted successfully!");  
  //       // Reset the form and navigation
  //       setFormData({
  //         gender: "",
  //         dob: "",
  //         disability: "",
  //         placeOfBirth: "",
  //         stateOfOrigin: "",
  //         lga: "",
  //         homeAddress: "",
  //         nextOfKinName: "",
  //         nextOfKinGsm: "",
  //         nextOfKinAddress: "",
  //         sponsorName: "",
  //         sponsorAddress: "",
  //         picture: null,
  //         educationDetails: [],
  //         olevelResults: {
  //           firstSitting: {
  //             year: "",
  //             examBody: "",
  //             examType: "",
  //             examNo: "",
  //             subjects: Array(5).fill({ subject: "", grade: "" }),
  //           },
  //           secondSitting: {
  //             year: "",
  //             examBody: "",
  //             examType: "",
  //             examNo: "",
  //             subjects: Array(5).fill({ subject: "", grade: "" }),
  //           },
  //         },
  //       });
  //       setCurrentStep(0); // Reset to the first step
  //       setFormStatus([false, false, false, false]); // Reset form status
  //       refreshPage();
  //     } else {
  //       alert("Error: " + result.message);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting all details:", error);
  //     alert("An error occurred. Please try again.");
  //   }
  // };
  const handleSubmitAll = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}submit-all.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: "include",
      });
      const result = await response.json();
      if (result.status === "success") {
        console.log("All details submitted successfully!");  
  
        // Reset the form and navigation
        setFormData({
          gender: "",
          dob: "",
          disability: "",
          placeOfBirth: "",
          stateOfOrigin: "",
          lga: "",
          homeAddress: "",
          nextOfKinName: "",
          nextOfKinGsm: "",
          nextOfKinAddress: "",
          sponsorName: "",
          sponsorAddress: "",
          picture: null,
          educationDetails: [],
          olevelResults: {
            firstSitting: {
              year: "",
              examBody: "",
              examType: "",
              examNo: "",
              subjects: Array(9).fill({ subject: "", grade: "" }),
            },
            secondSitting: {
              year: "",
              examBody: "",
              examType: "",
              examNo: "",
              subjects: Array(9).fill({ subject: "", grade: "" }),
            },
          },
        });
        setFormStatus([false, false, false, false]); // Reset form status
  
        // Set the current step back to the first screen
        setCurrentStep(0);
  
        // Optionally, delay the reset to allow for a full re-render
        setTimeout(() => {
          window.location.reload();
        }, 500); // 500ms delay to refresh the page
  
      } else {
        alert("Error: " + result.message);
      }
    } catch (error) {
      console.error("Error submitting all details:", error);
      alert("An error occurred. Please try again.");
    }
  };
  
  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Handle logout by clearing session and navigating to login page
  const handleLogout = () => {
    // Clear session data on the backend (if needed)
    axios
      .post(
        `${API_BASE_URL}logout.php`,
        {},
        { withCredentials: true }
      )
      .then(() => {
        navigate("/login"); // Navigate to the login page
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        alert("An error occurred during logout. Please try again.");
      });
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  const getImageSource = () => {
    return userInfo && userExist
      ? `${API_BASE_URL}/profile.php` // This should return either the actual image or the placeholder
      : imgHolder; // Placeholder if user info doesn't exist
  };

  return (
    <div className="dashboard">
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <FaBars />
      </button>
      <aside
        className={`side-nav ${isSidebarOpen ? "open" : ""} ${
          currentStep === 4 ? "hidden" : ""
        }`}
      >
        <div className="logo">
          <img src={getImageSource()} alt="Company" className="company-logo" />
        </div>
        <nav>
          <ul>
            <li onClick={() => navigate("/dashboard")}>
              <FaHome /> Dashboard
            </li>
            <li onClick={() => navigate('/ApplicationSummary')}>
              <FaFolderOpen /> My Application
            </li>
           
            <li onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </li>
          </ul>
        </nav>
      </aside>
      <main className="main-content">
        {/* <div className={`search-bar ${currentStep === 5 ? "hidden" : ""}`}>
          <FaSearch />
          <input type="text" placeholder="Search..." />
        </div> */}
        {currentStep === 0 && (
          <>
            <section className="dashboard-section">
              <h2>Dashboard</h2>
              <p>My UTME Application Portal</p>
              {userExist ? (
            <button onClick={() => navigate('/ApplicationSummary')}>Print Application</button>
                  ) : (
            <button onClick={handleContinueClick}>Start Application</button>
                )}
            </section>
            <section className="transaction-receipts">
              <h2>Profile Details</h2>
              {error && <p className="error">{error}</p>}
              {userInfo ? (
                <div className="receipts">
                  <p>
                    Full Name: {userInfo.firstName} {userInfo.lastName}{" "}
                    {userInfo.otherName}
                  </p>
                  <p>Email: {userInfo.email}</p>
                  
                  <p>Phone Number: {userInfo.phoneNumber}</p>
                  <p>Application Number: {userInfo.applicationNo}</p>
                  <p>Programme: {userInfo.programme}</p>
                  <p>Jamb Score: {userInfo.jambScore}</p>
                  <p>Jamb Number: {userInfo.jambNumber}</p>
                  <p>School Chosen: {userInfo.school}</p>
                  <p>Session: {userInfo.session}</p>
                </div>
              ) : (
                <p>Loading user information...</p>
              )}
            </section>
          </>
        )}
        {currentStep === 1 && (
          <section className="form-section">
            <BioForm
              formData={formData}
              onSubmitSuccess={(data) => handleFormSubmit(data, 0)}
            />
          </section>
        )}
        {currentStep === 2 && (
          <section className="edu-form-section">
            <EduForm
              formData={formData}
              onSubmitSuccess={(data) => handleFormSubmit(data, 1)}
            />
          </section>
        )}
        {currentStep === 3 && (
          <section className="form-section">
            <OLevelResultsForm
              formData={formData}
              onSubmitSuccess={(data) => handleFormSubmit(data, 2)}
            />
          </section>
        )}
        {currentStep === 4 && (
          <section className="form-section">
           
          <ReviewForm formData={formData} userInfo={userInfo} onSubmitAll={() => handleSubmitAll({}, 4)} />

          </section>
        )}
        {/* {currentStep === 5 && (
          <section className="form-section">
            <SubmissionDetails />
          </section>
        )} */}
        {currentStep !== 5 && (
          <div className="navigation-buttons">
            {currentStep > 0 && (
              <button onClick={handlePreviousClick}>Previous</button>
            )}
            {currentStep < 4 && (
              <button
                onClick={() => {
                  if (formStatus[currentStep] || currentStep < 4) {
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                Next
              </button>
            )}
          </div>
        )}
        <div className="form-indicator">
          <p>Current Form: {currentStep + 1}/5</p>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
