import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "./Config";
import "./ApplicationSummary.css";

const ApplicationSummary = () => {
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}user-info.php`, {
          withCredentials: true,
        });

        if (response.data.status === "success") {
          setApplication(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError("An error occurred while fetching the application. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, []);

  const handleViewApplication = () => {
    navigate(`/SubmittedApplication/${encodeURIComponent(application.applicationNo)}`);
  };
  

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  if (loading) {
    return <p>Loading application details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!application) {
    return <p>No application found.</p>;
  }

  return (
    <div className="application-summary-container">
      <h2>Your Application</h2>
      <table className="application-table">
        <thead>
          <tr>
            <th>Application Number</th>
            <th>Programme</th>
            <th>Session</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{application.applicationNo}</td>
            <td>{application.programme}</td>
            <td>{application.session}</td>
            <td>
              <button className="view-button" onClick={handleViewApplication}>
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="button-container">
        <button className="back-button" onClick={handleBackToDashboard}>
          Back to Dashboard
        </button>
      </div>
    </div>
  );
};

export default ApplicationSummary;
